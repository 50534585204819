import { Loader } from '@onsaui'
import { Outlet, useParams } from 'react-router-dom'

import GeneralLayout from '@/modules/general/components/GeneralLayout/GeneralLayout'
import WorkspaceHeader from '@/modules/general/components/WorkspaceHeader/WorkspaceHeader'
import WorkspaceSidebar from '@/modules/general/components/WorkspaceSidebar/WorkspaceSidebar'
import useIcp from '@/modules/icp/hooks/useIcp'
import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'

const IcpWrapperPage: React.FC = () => {
  const { icpId } = useParams()
  const { data: icp, isPending, isError, error } = useIcp(icpId!)

  let body
  if (isPending) {
    body = (
      <div className="flex flex-grow items-center justify-center">
        <Loader />
      </div>
    )
  } else if (isError) {
    body = (
      <div className="flex flex-grow items-center justify-center">
        <div className="text-danger">{getErrorMessage(error)}</div>
      </div>
    )
  } else {
    body = <Outlet />
  }

  return (
    <GeneralLayout
      sidebar={<WorkspaceSidebar />}
      header={<WorkspaceHeader />}
      body={
        <div className="mx-auto flex w-full max-w-5xl flex-grow flex-col px-6 pb-8">{body}</div>
      }
    />
  )
}

export default IcpWrapperPage
