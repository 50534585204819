import { notify } from '@onsaui'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { icpActions } from '@/modules/icp'
import { IcpStage } from '@/modules/icp/model'
import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'

const useIcpActions = () => {
  const [isDeleting, setIsDeleting] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)

  const { workspaceId, icpId: paramIcpId } = useParams()
  const navigate = useNavigate()

  const updateIcp = async (
    icpId: string,
    data: Partial<{ context: string; body: string; stage: IcpStage }>,
    config?: { silent?: boolean },
  ) => {
    setIsUpdating(true)
    try {
      const updatedIcp = await icpActions.updateIcp(icpId, data)
      if (!config?.silent) {
        notify({ message: 'Saved', variant: 'success' })
      }

      return updatedIcp
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
      throw e
    } finally {
      setIsUpdating(false)
    }
  }

  const updateIcpStage = async (icpId: string, stage: IcpStage) => {
    return updateIcp(icpId, { stage }, { silent: true })
  }

  const deleteIcp = async (icpId: string, onDeleted?: () => void) => {
    setIsDeleting(true)

    let isDeleted = false
    try {
      await icpActions.deleteIcp(icpId)

      if (paramIcpId === icpId) {
        if (workspaceId) {
          navigate(`/${workspaceId}`)
        } else {
          navigate('/')
        }
      }

      isDeleted = true
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
      throw e
    } finally {
      setIsDeleting(false)
    }

    if (isDeleted) {
      onDeleted?.()
    }
  }

  return { deleteIcp, isDeleting, updateIcp, updateIcpStage, isUpdating }
}

export default useIcpActions
