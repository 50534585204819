import { icpLeadsApi } from '@/modules/icp/api'
import { IcpLead, IcpLeadsWithStatus, LeadStatus } from '@/modules/icp/model'
import { TaskStatus } from '@/modules/task/model'
import { QueryService } from '@/services/query'

export const invalidateIcpReferenceLeads = (icpId: string) => {
  QueryService.getClient().invalidateQueries({ queryKey: ['icpLeads', icpId] })
}

export const generateIcpReferenceLeads = async (icpId: string) => {
  await icpLeadsApi.generateIcpReferenceLeads(icpId)

  QueryService.getClient().setQueriesData(
    { queryKey: ['icpReferenceLeads', icpId] },
    (oldData?: IcpLeadsWithStatus) => {
      if (!oldData) {
        return
      }

      return {
        ...oldData,
        status: TaskStatus.Processing,
      }
    },
  )

  invalidateIcpReferenceLeads(icpId)
}

export const updateIcpLead = async (icpId: string, leadId: string, changes: Partial<IcpLead>) => {
  const lead = await icpLeadsApi.updateIcpLead(icpId, leadId, changes)

  QueryService.getClient().setQueriesData(
    { queryKey: ['icpReferenceLeads', icpId] },
    (oldData?: IcpLeadsWithStatus) => {
      if (!oldData) {
        return
      }

      let { leads } = oldData
      if (lead.status === LeadStatus.Deleted) {
        leads = leads.filter((oldLead) => oldLead.id !== leadId)
      } else {
        leads = leads.map((oldLead) => (oldLead.id === leadId ? lead : oldLead))
      }

      return { ...oldData, leads }
    },
  )

  invalidateIcpReferenceLeads(icpId)

  return lead
}

export const deleteIcpLead = async (icpId: string, leadId: string, comment?: string) => {
  return updateIcpLead(icpId, leadId, { status: LeadStatus.Deleted, comment })
}
