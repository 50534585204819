import { Loader } from '@onsaui'
import cx from 'clsx'

import useIcp from '@/modules/icp/hooks/useIcp'
import useIcpPoling from '@/modules/icp/hooks/useIcpPoling'
import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'
import { TaskStatus } from '@/modules/task/model'

import IcpBody from './IcpBody'
import IcpContext from './IcpContext'

const IcpContextWidget: React.FC<{ icpId: string; className?: string }> = ({
  icpId,
  className,
}) => {
  const { data: icp, isPending, isError, error } = useIcp(icpId)
  useIcpPoling(icpId!)

  let body = null
  if (isPending) {
    body = (
      <div className="flex flex-grow flex-col items-center justify-center">
        <Loader />
      </div>
    )
  } else if (isError) {
    body = (
      <div className="flex flex-grow flex-col items-center justify-center">
        <p className="text-danger">{getErrorMessage(error)}</p>
      </div>
    )
  } else if (icp.status === TaskStatus.Completed) {
    body = (
      <div className={cx('flex flex-col gap-6 xl:flex-row', className)}>
        <IcpBody icp={icp} className="flex-grow" />

        <IcpContext icp={icp} />
      </div>
    )
  } else if (icp.status === TaskStatus.Failed) {
    body = <p className="text-danger">Unable to load ICP...</p>
  } else {
    body = (
      <div className="flex flex-grow flex-col items-center justify-center">
        <h2 className="mb-1">We are generating ICP for you</h2>
        <p className="mb-4">It may take a few minutes...</p>

        <Loader />
      </div>
    )
  }

  return body
}

export default IcpContextWidget
