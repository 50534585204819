import { Button, Input, notify, TextArea } from '@onsaui'
import { IconPlusSm } from '@onsaui/icons'
import cx from 'clsx'
import { useEffect, useRef, useState } from 'react'

import useOutreachTemplateActions from '@/modules/icp/hooks/useOutreachTemplateActions'

const OutreachTemplateDraft: React.FC<{
  icpId: string
  onCancel: () => void
  className?: string
}> = ({ icpId, onCancel, className }) => {
  const { createOutreachTemplate, isCreating } = useOutreachTemplateActions()

  const [title, setTitle] = useState('New Template')
  const [message, setMessage] = useState('')

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value)
  }
  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value)
  }

  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [])

  const handleSave = async () => {
    const canBeSaved = title.trim().length > 0 && message.trim().length > 0
    if (!canBeSaved) {
      notify({ message: 'Title and Message should not be empty', variant: 'error' })
      return
    }

    await createOutreachTemplate(icpId, title.trim(), message.trim(), 10)
    onCancel()
  }

  const handleClose = () => {
    onCancel()
  }

  return (
    <div className={cx('rounded-3xl bg-level1 p-5', className)} ref={ref}>
      <Input
        className="w-full"
        inputClassName="!rounded-2xl"
        placeholder="Template title"
        onChange={handleTitleChange}
        maxLength={200}
        value={title}
        isDisabled={isCreating}
      />

      <TextArea
        className="mt-2 w-full"
        inputClassName="!rounded-2xl"
        placeholder="Template message"
        autoHeight={{ enabled: true, minLines: 3, maxLines: 10 }}
        onChange={handleMessageChange}
        value={message}
        isDisabled={isCreating}
        autoFocus
      />

      <div className="mt-4 flex flex-row justify-end gap-2">
        <Button
          type="button"
          size="medium"
          variant="outlined"
          onClick={handleClose}
          isDisabled={isCreating}
        >
          Cancel
        </Button>
        <Button
          type="button"
          size="medium"
          variant="primary"
          onClick={handleSave}
          isDisabled={isCreating}
        >
          <span>Save</span>
        </Button>
      </div>
    </div>
  )
}

export default OutreachTemplateDraft
