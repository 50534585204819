import { NotificationsRoot, TooltipProvider } from '@onsaui'
import { QueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'

import AuthGuard from '@/modules/auth/components/AuthGuard/AuthGuard'
import AuthModal from '@/modules/auth/components/AuthModal/AuthModal'
import SignInToContinueModal from '@/modules/auth/components/SignInToContinueModal/SignInToContinueModal'
import BillingPlanSelectModal from '@/modules/billing/components/BillingPlanSelectModal/BillingPlanSelectModal'
import ContactSalesModal from '@/modules/billing/components/ContactSalesModal/ContactSalesModal'
import PaymentFailedModal from '@/modules/billing/components/PaymentFailedModal/PaymentFailedModal'
import PaymentSuccessModal from '@/modules/billing/components/PaymentSuccessModal/PaymentSuccessModal'
import UpgradeToProModal from '@/modules/billing/components/UpgradeToProModal/UpgradeToProModal'
import UpgradeToProPromoModal from '@/modules/billing/components/UpgradeToProPromoModal/UpgradeToProPromoModal'
import IcpLeadDeletingModal from '@/modules/icp/components/IcpLeadDeletingModal/IcpLeadDeletingModal'
import IcpLeadScoringModal from '@/modules/icp/components/IcpLeadScoringModal/IcpLeadScoringModal'
import { icpHelpers, IcpStage } from '@/modules/icp/model'
import RegenerateTaskModal from '@/modules/task/components/RegenerateTaskModal/RegenerateTaskModal'
import TaskSharingModal from '@/modules/task/components/TaskSharingModal/TaskSharingModal'
import RedirectInsideWorkspace from '@/modules/workspace/components/RedirectInsideWorkspace/RedirectInsideWorkspace'
import SystemPromptSettingsModal from '@/modules/workspace/components/SystemPromptSettingsModal/SystemPromptSettingsModal'
import AuthRedirectPage from '@/pages/auth-redirect/AuthRedirectPage'
import HomePage from '@/pages/home/HomePage'
import IcpConfirmationPage from '@/pages/icp-confirmation/IcpConfirmationPage'
import IcpContextPage from '@/pages/icp-context/IcpContextPage'
import IcpEditRedirect from '@/pages/icp-edit-redirect/IcpEditRedirect'
import IcpLeadsPage from '@/pages/icp-leads/IcpLeadsPage'
import IcpRootPage from '@/pages/icp-root-page/IcpRootPage'
import IcpTemplatesPage from '@/pages/icp-templates/IcpTemplatesPage'
import IcpWrapperPage from '@/pages/icp-wrapper/IcpWrapperPage'
import PlaygroundPage from '@/pages/playground/PlaygroundPage'
import PlaygroundTaskPage from '@/pages/playground-task/PlaygroundTaskPage'
import PublicTaskPage from '@/pages/public-task/PublicTaskPage'
import SalesBotPage from '@/pages/sales-bot/SalesBotPage'
import SignInPage from '@/pages/sign-in/SignInPage'
import SignOutPage from '@/pages/sign-out/SignOutPage'
import TestUIPage from '@/pages/test-ui/TestUIPage'
import WorkspacePage from '@/pages/workspace/WorkspacePage'
import WorkspaceTaskPage from '@/pages/workspace-task/WorkspaceTaskPage'
import { QueryService } from '@/services/query'

const Root: React.FC = () => {
  return (
    // <ColorTheme>
    <QueryClientProvider client={QueryService.getClient()}>
      <TooltipProvider>
        <BrowserRouter>
          <Routes>
            <Route
              element={
                <>
                  <NotificationsRoot />

                  <AuthModal />
                  <SignInToContinueModal />
                  <UpgradeToProPromoModal />
                  <ContactSalesModal />
                  <UpgradeToProModal />
                  <BillingPlanSelectModal />
                  <PaymentSuccessModal />
                  <PaymentFailedModal />
                  <RegenerateTaskModal />
                  <TaskSharingModal />
                  <IcpLeadScoringModal />
                  <IcpLeadDeletingModal />

                  <SystemPromptSettingsModal />

                  <Outlet />
                </>
              }
            >
              <Route path="auth" element={<AuthRedirectPage />} />
              <Route path="sign-in" element={<SignInPage />} />
              <Route path="sign-out" element={<SignOutPage />} />

              <Route path="test-ui" element={<TestUIPage />} />
              <Route path="sales-bot" element={<SalesBotPage />} />

              <Route path="/" element={<HomePage />} />

              <Route path="playground" element={<PlaygroundPage />} />
              <Route path="playground/t/:taskId" element={<PlaygroundTaskPage />} />

              <Route
                path="shared/t/:taskPublicId"
                element={
                  <RedirectInsideWorkspace>
                    <PublicTaskPage />
                  </RedirectInsideWorkspace>
                }
              />

              <Route
                path=":workspaceId"
                element={
                  <AuthGuard strategy="redirect_signin">
                    <Outlet />
                  </AuthGuard>
                }
              >
                <Route index element={<WorkspacePage />} />
                <Route path="t/:taskId" element={<WorkspaceTaskPage />} />
                <Route path="shared/t/:taskPublicId" element={<PublicTaskPage />} />

                <Route path="icp/:icpId" element={<IcpWrapperPage />}>
                  <Route index element={<IcpRootPage />} />

                  <Route path="edit">
                    <Route index element={<IcpEditRedirect />} />
                    <Route
                      path={icpHelpers.getIcpStageRoute(IcpStage.SetContext)}
                      element={<IcpContextPage />}
                    />
                    <Route
                      path={icpHelpers.getIcpStageRoute(IcpStage.ReferenceLeads)}
                      element={<IcpLeadsPage />}
                    />
                    <Route
                      path={icpHelpers.getIcpStageRoute(IcpStage.MessageTemplates)}
                      element={<IcpTemplatesPage />}
                    />
                    <Route
                      path={icpHelpers.getIcpStageRoute(IcpStage.Confirmation)}
                      element={<IcpConfirmationPage />}
                    />
                  </Route>
                </Route>
              </Route>
            </Route>

            <Route path="*" element={<div>Page not found...</div>} />
          </Routes>
        </BrowserRouter>
      </TooltipProvider>
    </QueryClientProvider>
    // </ColorTheme>
  )
}

export default Root
