import { TaskStatus } from '@/modules/task/model'

export enum IcpStage {
  SetContext = 'set-context',
  ReferenceLeads = 'reference-leads',
  MessageTemplates = 'message-templates',
  Confirmation = 'confirmation',
  Finalized = 'finalized',
}

export interface Icp {
  id: string
  title: string
  linkedInUrl: string | null
  context: string
  files: string[]
  body: string
  offerings: string[]
  status: TaskStatus
  stage: IcpStage
}

export interface IcpMinimal {
  id: string
  title: string
}
