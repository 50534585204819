import { Button } from '@onsaui'
import { IconCheckCircle, IconCheckCircleFilled } from '@onsaui/icons'
import cx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'

import useIcp from '@/modules/icp/hooks/useIcp'
import useIcpActions from '@/modules/icp/hooks/useIcpActions'
import { icpHelpers, IcpStage } from '@/modules/icp/model'
import { TaskStatus } from '@/modules/task/model'

const stages = [
  {
    label: 'Context Added',
    stage: IcpStage.SetContext,
    route: icpHelpers.getIcpStageRoute(IcpStage.SetContext),

    nextStage: IcpStage.ReferenceLeads,
    nextStageRoute: icpHelpers.getIcpStageRoute(IcpStage.ReferenceLeads),
  },
  {
    label: 'References Added',
    stage: IcpStage.ReferenceLeads,
    route: icpHelpers.getIcpStageRoute(IcpStage.ReferenceLeads),

    nextStage: IcpStage.MessageTemplates,
    nextStageRoute: icpHelpers.getIcpStageRoute(IcpStage.MessageTemplates),
  },
  {
    label: 'Templates Added',
    stage: IcpStage.MessageTemplates,
    route: icpHelpers.getIcpStageRoute(IcpStage.MessageTemplates),

    nextStage: IcpStage.Confirmation,
    nextStageRoute: icpHelpers.getIcpStageRoute(IcpStage.Confirmation),
  },
]
const EditableStages = [IcpStage.SetContext, IcpStage.ReferenceLeads, IcpStage.MessageTemplates]

const IcpStageWidget: React.FC<{ icpId: string; currentStage: IcpStage; className?: string }> = ({
  icpId,
  currentStage,
  className,
}) => {
  const navigate = useNavigate()

  const { data: icp, isPending, isError, error } = useIcp(icpId)

  const { updateIcpStage, isUpdating } = useIcpActions()

  const handleNextStage = async () => {
    const stageConfig = stages.find((s) => s.stage === currentStage)!

    await updateIcpStage(icpId, stageConfig.nextStage)
    navigate(`../${stageConfig.nextStageRoute}`)
  }
  const handleFinalize = async () => {
    await updateIcpStage(icpId, IcpStage.Finalized)
    navigate(`../..`)
  }

  return (
    <div className={cx('flex flex-row items-center gap-6 rounded-3xl bg-level1 p-5', className)}>
      <div className="flex flex-grow flex-row gap-4">
        {stages.map(({ label, stage, route }) => {
          const isCompleted = icp ? icpHelpers.isStageCompleted(stage, icp.stage) : false
          const isCurrentStage = stage === currentStage

          return (
            <Link to={`../${route}`} key={stage}>
              <div
                className={cx(
                  'flex flex-row items-center gap-2',
                  isCompleted || isCurrentStage ? 'text-primary' : 'text-secondary',
                )}
              >
                {isCompleted ? <IconCheckCircleFilled /> : <IconCheckCircle />}
                <p>{label}</p>
              </div>
            </Link>
          )
        })}
      </div>

      {icp && EditableStages.includes(currentStage) ? (
        <Button
          onClick={handleNextStage}
          isDisabled={isUpdating || icp.status !== TaskStatus.Completed}
          isLoading={isUpdating}
        >
          Looks Good
        </Button>
      ) : null}
      {icp && currentStage === IcpStage.Confirmation ? (
        <Button
          onClick={handleFinalize}
          isDisabled={isUpdating || icp.status !== TaskStatus.Completed}
          isLoading={isUpdating}
        >
          Finalize
        </Button>
      ) : null}
    </div>
  )
}

export default IcpStageWidget
