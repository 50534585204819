import { Loader } from '@onsaui'
import cx from 'clsx'
import React from 'react'

import useIcpReferenceLeads from '@/modules/icp/hooks/useIcpReferenceLeads'
import { IcpLead } from '@/modules/icp/model'
import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'

const EnsureIcpLead: React.FC<{
  icpId: string
  leadId: string
  children: (icpLead: IcpLead) => React.ReactNode
  className?: string
}> = ({ icpId, leadId, children, className }) => {
  const { data: leadsWithStatus, isPending, isError, error } = useIcpReferenceLeads(icpId)

  let body
  if (isPending) {
    body = (
      <div className="flex flex-grow items-center justify-center">
        <Loader />
      </div>
    )
  } else if (isError) {
    body = (
      <div className="flex flex-grow items-center justify-center">
        <p className="text-danger">{getErrorMessage(error)}</p>
      </div>
    )
  } else {
    const lead = leadsWithStatus?.leads.find((l) => l.id === leadId)
    if (!lead) {
      body = <p className="text-danger">Lead not found</p>
    } else {
      body = children(lead)
    }
  }

  return <div className={cx('flex flex-col', className)}>{body}</div>
}

export default EnsureIcpLead
