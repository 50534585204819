import { useParams } from 'react-router-dom'

import EnsureProcessedIcp from '@/modules/icp/components/EnsureProcessedIcp/EnsureProcessedIcp'
import IcpStageWidget from '@/modules/icp/components/IcpStageWidget/IcpStageWidget'
import { IcpStage } from '@/modules/icp/model'

const IcpConfirmationPage: React.FC = () => {
  const { icpId } = useParams()
  return (
    <>
      <IcpStageWidget icpId={icpId!} currentStage={IcpStage.Confirmation} className="mb-5" />
      <EnsureProcessedIcp icpId={icpId!}>
        <div>Confirm</div>
      </EnsureProcessedIcp>
    </>
  )
}

export default IcpConfirmationPage
