import { Loader } from '@onsaui'
import { IconPlusSm } from '@onsaui/icons'
import cx from 'clsx'
import { useState } from 'react'

import useOutreachTemplates from '@/modules/icp/hooks/useOutreachTemplates'
import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'

import OutreachTemplateDraft from './OutreachTemplateDraft'
import OutreachTemplateItem from './OutreachTemplateItem'

const OutreachTemplatesWidget: React.FC<{ icpId: string; className?: string }> = ({
  icpId,
  className,
}) => {
  const { data: outreachTemplates, isPending, isError, error } = useOutreachTemplates(icpId)

  const [isDraft, setIsDraft] = useState(false)
  const showDraft = () => {
    setIsDraft(true)
  }
  const hideDraft = () => {
    setIsDraft(false)
  }

  const sortedOutreachTemplates = [...(outreachTemplates ?? [])].sort((a, b) => {
    return b.position - a.position
  })

  let body
  if (isPending) {
    body = <Loader />
  } else if (isError) {
    body = <p className="text-danger">{getErrorMessage(error)}</p>
  } else {
    body = (
      <>
        {sortedOutreachTemplates.map((outreachTemplate) => (
          <OutreachTemplateItem
            key={outreachTemplate.id}
            icpId={icpId}
            outreachTemplate={outreachTemplate}
          />
        ))}

        {isDraft ? (
          <OutreachTemplateDraft icpId={icpId} onCancel={hideDraft} />
        ) : (
          <button
            type="button"
            className="flex h-16 flex-row items-center justify-center gap-2 rounded-xl border border-dashed border-default bg-level1 transition-shadow hover:shadow-md"
            onClick={showDraft}
          >
            <span>Add New Template</span>
            <IconPlusSm />
          </button>
        )}
      </>
    )
  }

  return (
    <div
      className={cx(
        'flex flex-col gap-5',
        { 'items-center justify-center': isPending || isError },
        className,
      )}
    >
      {body}
    </div>
  )
}

export default OutreachTemplatesWidget
