import { Button, notify, TextArea } from '@onsaui'
import cx from 'clsx'
import { useState } from 'react'

import useIcpLeadsActions from '@/modules/icp/hooks/useIcpLeadsActions'
import { IcpLead } from '@/modules/icp/model'

const ScoringForm: React.FC<{
  icpId: string
  lead: IcpLead
  onSaved?: () => void
  className?: string
}> = ({ icpId, lead, onSaved, className }) => {
  const [score, setScore] = useState(lead.score)
  const [comment, setComment] = useState(lead.comment ?? '')
  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value)
  }

  const { updateIcpLead, isUpdating } = useIcpLeadsActions()

  const handleSave = async () => {
    await updateIcpLead(icpId, lead.id, { score, comment })

    notify({ message: 'Saved', variant: 'success' })
    onSaved?.()
  }

  return (
    <div className={cx('', className)}>
      <h2 className="mb-6 text-center font-semibold">
        <p>Rate reference:</p>
        <a href={lead.linkedInUrl} target="_blank" rel="noreferrer" className="ml-1 text-primary">
          {lead.fullName}
        </a>
      </h2>

      <div className="mb-4">
        <p className="mb-1">Score</p>
        <div className="flex flex-row items-center gap-2">
          {Array.from({ length: 10 }, (_, i) => {
            const btnScore = i + 1
            const isCurrent = btnScore === score
            return (
              <button
                key={btnScore}
                type="button"
                className={cx(
                  'flex w-8 shrink-0 items-center justify-center rounded-xl border border-default p-2',
                  { 'border-transparent bg-primary text-white': isCurrent },
                )}
                onClick={() => setScore(btnScore)}
              >
                {btnScore}
              </button>
            )
          })}
        </div>
      </div>

      <div className="mb-6">
        <p className="mb-1">Comment</p>
        <TextArea
          autoHeight={{ enabled: true, minLines: 3, maxLines: 5 }}
          placeholder="Why this lead is good or bad?"
          value={comment}
          onChange={handleCommentChange}
        />
      </div>

      <Button
        className="w-full"
        type="button"
        onClick={handleSave}
        isLoading={isUpdating}
        isDisabled={isUpdating}
      >
        Save
      </Button>
    </div>
  )
}

export default ScoringForm
