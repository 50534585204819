import { IconEdit, IconTrash } from '@onsaui/icons'
import cx from 'clsx'

import { openIcpLeadDeletingModal, openIcpLeadScoringModal } from '@/modules/icp'
import { IcpLead } from '@/modules/icp/model'
import NamedAvatar from '@/shared/component/NamedAvatar/NamedAvatar'

const IcpLeadItem: React.FC<{
  icpId: string
  icpLead: IcpLead
  className?: string
}> = ({ icpId, icpLead, className }) => {
  const handleDelete = async () => {
    openIcpLeadDeletingModal(icpId, icpLead.id)
  }

  const handleEdit = () => {
    openIcpLeadScoringModal(icpId, icpLead.id)
  }

  return (
    <div
      key={icpLead.id}
      className={cx(
        'flex items-center justify-between gap-4 rounded-xl border border-disabled p-4',
        className,
      )}
    >
      <div className="flex flex-row items-center gap-4">
        <NamedAvatar
          name={icpLead.fullName}
          className="h-10 w-10 shrink-0 self-start rounded-full"
        />

        <div>
          <div className="mb-2 flex flex-row">
            <a className="text-primary" href={icpLead.linkedInUrl} target="_blank" rel="noreferrer">
              {icpLead.fullName}
            </a>

            <p className="ml-4">
              Score: <span className="font-semibold">{icpLead.score}/10</span>
            </p>
          </div>

          <div className="text-sm">
            <span>Role: {icpLead.position}</span>
          </div>
          <div className="text-sm">
            <span>Company:</span>
            <a
              className="ml-1 inline-block text-primary"
              href={icpLead.companyLinkedInUrl}
              target="_blank"
              rel="noreferrer"
            >
              {icpLead.companyName}
            </a>
          </div>
        </div>
      </div>

      <div className="shrink-0">
        <button
          type="button"
          className="p-2 transition-all hover:text-primary"
          onClick={handleEdit}
          aria-label="Edit"
        >
          <IconEdit />
        </button>

        <button
          type="button"
          className="ml-2 p-2 transition-all hover:text-danger"
          onClick={handleDelete}
          aria-label="Remove"
        >
          <IconTrash />
        </button>
      </div>
    </div>
  )
}

export default IcpLeadItem
