import { Modal } from '@onsaui'

import { closeIcpLeadDeletingModal, useIcpStore } from '@/modules/icp'

import EnsureIcpLead from '../EnsureIcpLead/EnsureIcpLead'
import DeletingForm from './DeletingForm'

const IcpLeadDeletingModal: React.FC = () => {
  const { leadDeletingModal } = useIcpStore()
  const { isOpen, icpId, leadId } = leadDeletingModal

  const handleClose = () => {
    closeIcpLeadDeletingModal()
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} className="w-full max-w-lg">
      {icpId ? (
        <EnsureIcpLead icpId={icpId!} leadId={leadId!} className="min-h-[200px]">
          {(lead) => <DeletingForm icpId={icpId!} lead={lead} onDeleted={handleClose} />}
        </EnsureIcpLead>
      ) : null}
    </Modal>
  )
}

export default IcpLeadDeletingModal
