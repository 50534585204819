import { useQuery } from '@tanstack/react-query'

import { icpLeadsApi } from '../api'

const useIcpReferenceLeads = (icpId: string) => {
  return useQuery({
    queryKey: ['icpReferenceLeads', icpId],
    queryFn: () => {
      return icpLeadsApi.getIcpReferenceLeads(icpId)
    },
  })
}

export default useIcpReferenceLeads
