import { notify } from '@onsaui'
import { useState } from 'react'

import { outreachTemplateActions } from '@/modules/icp'
import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'

import { OutreachTemplate } from '../model'

const useOutreachTemplateActions = () => {
  const [isDeleting, setIsDeleting] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)

  const createOutreachTemplate = async (
    icpId: string,
    title: string,
    message: string,
    position: number = 100,
  ) => {
    setIsCreating(true)

    try {
      const template = await outreachTemplateActions.createOutreachTemplate(
        icpId,
        title,
        message,
        position,
      )
      return template
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
      throw e
    } finally {
      setIsCreating(false)
    }
  }

  const updateOutreachTemplate = async (
    icpId: string,
    templateId: string,
    changes: Partial<OutreachTemplate>,
  ) => {
    setIsUpdating(true)

    try {
      const template = await outreachTemplateActions.updateOutreachTemplate(
        icpId,
        templateId,
        changes,
      )
      return template
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
      throw e
    } finally {
      setIsUpdating(false)
    }
  }

  const deleteOutreachTemplate = async (
    icpId: string,
    templateId: string,
    onDeleted?: () => void,
  ) => {
    setIsDeleting(true)

    let isDeleted = false
    try {
      await outreachTemplateActions.deleteOutreachTemplate(icpId, templateId)

      isDeleted = true
    } catch (e) {
      notify({ message: getErrorMessage(e), variant: 'error' })
      throw e
    } finally {
      setIsDeleting(false)
    }

    if (isDeleted) {
      onDeleted?.()
    }
  }

  return {
    deleteOutreachTemplate,
    isDeleting,
    createOutreachTemplate,
    isCreating,
    updateOutreachTemplate,
    isUpdating,
  }
}

export default useOutreachTemplateActions
