import { create } from 'zustand'

export interface IcpStore {
  leadScoringModal: {
    isOpen: boolean
    icpId?: string
    leadId?: string
  }
  leadDeletingModal: {
    isOpen: boolean
    icpId?: string
    leadId?: string
  }
}

export const useIcpStore = create<IcpStore>((set) => ({
  leadScoringModal: {
    isOpen: false,
  },
  leadDeletingModal: {
    isOpen: false,
  },
}))

export const openIcpLeadScoringModal = (icpId: string, leadId: string) => {
  useIcpStore.setState({ leadScoringModal: { isOpen: true, icpId, leadId } })
}
export const closeIcpLeadScoringModal = () => {
  useIcpStore.setState({ leadScoringModal: { isOpen: false } })
}

export const openIcpLeadDeletingModal = (icpId: string, leadId: string) => {
  useIcpStore.setState({ leadDeletingModal: { isOpen: true, icpId, leadId } })
}
export const closeIcpLeadDeletingModal = () => {
  useIcpStore.setState({ leadDeletingModal: { isOpen: false } })
}

// @ts-expect-error - expose to the window
window.openIcpLeadScoringModal = openIcpLeadScoringModal

// @ts-expect-error - expose to the window
window.openIcpLeadDeletingModal = openIcpLeadDeletingModal
