/* eslint-disable react/no-children-prop */
import { Button } from '@onsaui'
import { IconEdit } from '@onsaui/icons'
import cx from 'clsx'
import { useRef, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import useIcpActions from '@/modules/icp/hooks/useIcpActions'
import { Icp } from '@/modules/icp/model'

const IcpBody: React.FC<{ icp: Icp; className?: string }> = ({ icp, className }) => {
  const { isUpdating, updateIcp } = useIcpActions()

  const editableElRef = useRef<HTMLDivElement>(null)
  const [isEditing, setIsEditing] = useState(false)
  const [body, setBody] = useState(icp.body)
  const startEditing = () => {
    setBody(icp.body)
    setIsEditing(true)
  }
  const handleSave = async () => {
    await updateIcp(icp.id, { body: editableElRef.current?.innerText || '' })
    setIsEditing(false)
  }

  return (
    <div className={cx('relative flex flex-col rounded-3xl bg-level1 p-10', className)}>
      <div className="mb-6 flex flex-row items-center">
        <h1 className="text-h2 font-semibold">{icp.title}</h1>
        <div className="w-4 flex-grow" />
        {!isEditing ? (
          <button type="button" onClick={startEditing} aria-label="Edit">
            <IconEdit />
          </button>
        ) : null}
      </div>

      {!isEditing ? (
        <ReactMarkdown
          className="prose prose-base prose-indigo prose-h1:mb-1 prose-h2:mb-0 prose-h3:mb-0 prose-h4:mb-0 prose-li:text-black prose-li:marker:text-primary"
          remarkPlugins={[remarkGfm]}
          children={icp.body}
        />
      ) : (
        <>
          <div ref={editableElRef} className="whitespace-pre-wrap outline-none" contentEditable>
            {body}
          </div>
          <Button
            className="mt-4 self-end"
            onClick={handleSave}
            isDisabled={isUpdating}
            isLoading={isUpdating}
          >
            Save
          </Button>
        </>
      )}
    </div>
  )
}

export default IcpBody
