import { useEffect, useRef } from 'react'

import { icpLeadsActions } from '@/modules/icp'
import { TaskStatus } from '@/modules/task/model'

import useIcpReferenceLeads from './useIcpReferenceLeads'

const useIcpReferenceLeadsPolling = (icpId: string) => {
  const { data: leadsWithStatus } = useIcpReferenceLeads(icpId)

  const statusRef = useRef(leadsWithStatus?.status)

  useEffect(() => {
    if (!leadsWithStatus) {
      return
    }
    statusRef.current = leadsWithStatus.status

    if (
      leadsWithStatus.status !== TaskStatus.Completed &&
      leadsWithStatus.status !== TaskStatus.Failed
    ) {
      const interval = setInterval(() => {
        icpLeadsActions.invalidateIcpReferenceLeads(icpId)
      }, 2000)
      return () => clearInterval(interval)
    }
  }, [leadsWithStatus, icpId])
}
export default useIcpReferenceLeadsPolling
