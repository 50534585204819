import { Button, Loader } from '@onsaui'
import { IconRetry } from '@onsaui/icons'
import cx from 'clsx'

import useIcpLeadsActions from '@/modules/icp/hooks/useIcpLeadsActions'
import useIcpReferenceLeads from '@/modules/icp/hooks/useIcpReferenceLeads'
import useIcpReferenceLeadsPolling from '@/modules/icp/hooks/useIcpReferenceLeadsPolling'
import getErrorMessage from '@/modules/shared/helpers/getErrorMessage'
import { TaskStatus } from '@/modules/task/model'

import IcpLeadItem from './IcpLeadItem'

const IcpLeadsWidget: React.FC<{ icpId: string; className?: string }> = ({ icpId, className }) => {
  const { data: leadsWithStatus, isPending, isError, error } = useIcpReferenceLeads(icpId)
  const { generateIcpReferenceLeads, isGenerating } = useIcpLeadsActions()

  useIcpReferenceLeadsPolling(icpId)

  const handleGenerateLeads = async () => {
    await generateIcpReferenceLeads(icpId)
  }

  let body
  if (isPending) {
    body = (
      <div className="flex flex-grow flex-col items-center justify-center">
        <Loader />
      </div>
    )
  } else if (isError) {
    body = (
      <div className="flex flex-grow flex-col items-center justify-center">
        <p className="text-danger">{getErrorMessage(error)}</p>
      </div>
    )
  } else if (leadsWithStatus.leads?.length) {
    body = (
      <div className="flex flex-col gap-4">
        {leadsWithStatus.leads.map((icpLead) => (
          <IcpLeadItem key={icpLead.id} icpId={icpId} icpLead={icpLead} />
        ))}

        {![TaskStatus.Completed, TaskStatus.Failed].includes(leadsWithStatus.status) && (
          <div className="flex flex-col items-center justify-center">
            <p className="mb-4"> Loading more leads</p>
            <Loader />
          </div>
        )}
      </div>
    )
  } else if (
    [TaskStatus.Completed, TaskStatus.Failed].includes(leadsWithStatus.status) &&
    leadsWithStatus.leads?.length === 0
  ) {
    body = (
      <div className="flex flex-grow flex-col items-center justify-center">
        <h3 className="mb-4">We didn&apos;t find any relevant leads 🙁</h3>
        <Button type="button" onClick={handleGenerateLeads}>
          Generate Again
        </Button>
      </div>
    )
  } else {
    body = (
      <div className="flex flex-grow flex-col items-center justify-center">
        <h2 className="mb-1">We are generating leads for you</h2>
        <p className="mb-4">It may take a few minutes...</p>

        <Loader />
      </div>
    )
  }

  return (
    <div className={cx('flex flex-col rounded-3xl bg-level1 p-5', className)}>
      <div className="mb-5 flex min-h-10 flex-row items-center justify-between">
        <h3 className="font-semibold">Choose References</h3>

        {leadsWithStatus &&
        leadsWithStatus.leads.length < 10 &&
        leadsWithStatus.status === TaskStatus.Completed ? (
          <Button
            variant="ghost"
            size="medium"
            className="!max-h-10 !min-h-10"
            onClick={handleGenerateLeads}
            isDisabled={isGenerating}
          >
            <span>Generate More</span>
            <IconRetry className="ml-2 h-5 w-5" />
          </Button>
        ) : null}
      </div>

      {body}
    </div>
  )
}

export default IcpLeadsWidget
