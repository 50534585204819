import { IcpLead, IcpLeadsWithStatus } from '@/modules/icp/model'

import { IcpLeadResponse, IcpLeadsWithStatusResponse } from './icpLeadsApiInterfaces'

export const icpLeadAdapter = (response: IcpLeadResponse) => {
  const icpLead: IcpLead = { ...response }

  return icpLead
}

export const icpLeadsWithStatusAdapter = (response: IcpLeadsWithStatusResponse) => {
  const icpLeadsWithStatus: IcpLeadsWithStatus = {
    status: response.status,
    leads: response.leads.map(icpLeadAdapter),
  }

  return icpLeadsWithStatus
}
