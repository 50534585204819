import { Button, notify, TextArea } from '@onsaui'
import cx from 'clsx'
import { useState } from 'react'

import useIcpLeadsActions from '@/modules/icp/hooks/useIcpLeadsActions'
import { IcpLead } from '@/modules/icp/model'

const DeletingForm: React.FC<{
  icpId: string
  lead: IcpLead
  onDeleted?: () => void
  className?: string
}> = ({ icpId, lead, onDeleted, className }) => {
  const [comment, setComment] = useState(lead.comment ?? '')
  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value)
  }

  const { deleteIcpLead, isDeleting } = useIcpLeadsActions()

  const handleDelete = async () => {
    await deleteIcpLead(icpId, lead.id, comment)

    notify({ message: 'Deleted', variant: 'success' })
    onDeleted?.()
  }

  return (
    <div className={cx('', className)}>
      <h2 className="mb-6 text-center font-semibold">
        <p>Are you sure you want to remove</p>
        <a href={lead.linkedInUrl} target="_blank" rel="noreferrer" className="ml-1 text-primary">
          {lead.fullName}
        </a>
        ?
      </h2>

      <div className="mb-6">
        <p className="mb-1">Comment</p>
        <TextArea
          autoHeight={{ enabled: true, minLines: 3, maxLines: 5 }}
          placeholder="Why this lead doesn't fit?"
          value={comment}
          onChange={handleCommentChange}
        />
      </div>

      <Button
        className="w-full"
        type="button"
        variant="primary"
        onClick={handleDelete}
        isLoading={isDeleting}
        isDisabled={isDeleting}
      >
        Remove This Lead
      </Button>
    </div>
  )
}

export default DeletingForm
