import { IconCopy, IconEdit, IconHeartEmpty, IconHeartFilled, IconTrash } from '@onsaui/icons'
import cx from 'clsx'
import { useEffect, useRef, useState } from 'react'

import useOutreachTemplateActions from '@/modules/icp/hooks/useOutreachTemplateActions'
import { OutreachTemplate } from '@/modules/icp/model'
import copyToClipboardAndNotify from '@/modules/shared/helpers/copyToClipboardAndNotify'

import OutreachTemplateItemEdit from './OutreachTemplateItemEdit'

const OutreachTemplateItem: React.FC<{
  icpId: string
  outreachTemplate: OutreachTemplate

  className?: string
}> = ({ icpId, outreachTemplate, className }) => {
  const { updateOutreachTemplate, isUpdating, deleteOutreachTemplate, isDeleting } =
    useOutreachTemplateActions()

  const [isEditing, setIsEditing] = useState(false)
  const startEditing = () => {
    setIsEditing(true)
  }
  const finishEditing = () => {
    setIsEditing(false)
  }

  const ref = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [])

  const handleLike = async () => {
    await updateOutreachTemplate(icpId, outreachTemplate.id, {
      liked: !outreachTemplate.liked,
    })
  }

  const handleCopy = () => {
    copyToClipboardAndNotify(outreachTemplate.message)
  }

  const handleDelete = async () => {
    await deleteOutreachTemplate(icpId, outreachTemplate.id)
  }

  if (isEditing) {
    return (
      <OutreachTemplateItemEdit
        icpId={icpId}
        outreachTemplate={outreachTemplate}
        onClose={finishEditing}
        className={className}
      />
    )
  }

  return (
    <div className={cx('flex flex-col rounded-3xl bg-[#D8E6FF] p-5', className)}>
      <div className="mb-4 flex flex-row items-center justify-between">
        <h3 className="font-semibold">{outreachTemplate.title}</h3>

        <div>
          <button
            key="edit"
            type="button"
            className="p-2 transition-all hover:text-primary"
            onClick={startEditing}
            aria-label="Edit"
          >
            <IconEdit />
          </button>
          <button
            key="copy"
            type="button"
            className="p-2 transition-all hover:text-primary"
            onClick={handleCopy}
            aria-label="Copy"
          >
            <IconCopy />
          </button>
          <button
            key="delete"
            type="button"
            className="p-2 transition-all hover:text-danger disabled:opacity-50"
            onClick={handleDelete}
            aria-label="Delete"
            disabled={isDeleting}
          >
            <IconTrash />
          </button>
          <button
            key="like"
            type="button"
            className="p-2 transition-all hover:text-primary"
            onClick={handleLike}
            aria-label="Like"
          >
            {outreachTemplate.liked ? <IconHeartFilled /> : <IconHeartEmpty />}
          </button>
        </div>
      </div>

      <p className="whitespace-pre-wrap">{outreachTemplate.message}</p>
    </div>
  )
}

export default OutreachTemplateItem
