import { Loader } from '@onsaui'

import useIcp from '@/modules/icp/hooks/useIcp'
import useIcpPoling from '@/modules/icp/hooks/useIcpPoling'
import { TaskStatus } from '@/modules/task/model'

const EnsureProcessedIcp: React.FC<{ children: React.ReactNode; icpId: string }> = ({
  children,
  icpId,
}) => {
  const { data: icp } = useIcp(icpId)
  useIcpPoling(icpId)

  if (icp?.status === TaskStatus.Completed) {
    return children
  }
  if (icp?.status === TaskStatus.Processing) {
    return (
      <div className="flex flex-grow flex-col items-center justify-center">
        <p className="mb-4">Updating icp...</p>
        <Loader />
      </div>
    )
  }

  return null
}

export default EnsureProcessedIcp
