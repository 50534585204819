import { TaskStatus } from '@/modules/task/model'

export interface IcpLeadsWithStatus {
  status: TaskStatus
  leads: IcpLead[]
}

export interface IcpLead {
  id: string
  fullName: string
  position: string
  linkedInUrl: string
  companyName: string
  companyLinkedInUrl: string
  score: number
  comment: string | null
  status: LeadStatus
  memoId: string | null
}

export enum LeadStatus {
  New = 'new',
  Approved = 'approved',
  Deleted = 'deleted',
  Contacted = 'contacted',
}
